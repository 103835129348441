import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import createStyles from '@material-ui/core/styles/createStyles'
import Grid from '@material-ui/core/Grid'
import SEO from './SEO'
import Paper from '@material-ui/core/Paper'
import Sidebar from '../components/Sidebar';
import Layout from '../components/Layout';
import Banner from '../components/Banner';


const styles = (theme) =>
  createStyles({
    paperLayout: {
      padding: theme.spacing.unit * 2,
      [theme.breakpoints.down("xs")]: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
      },
    },
    layout: {
      width: 'auto',

      [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
        width: 1100,
        marginLeft: 'auto',
        marginRight: 'auto',
      },

      paddingLeft: theme.spacing.unit,
      paddingRight: theme.spacing.unit,
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 0,
        paddingRight: 0,
      },

      boxSizing: "content-box",

      paddingTop: theme.spacing.unit * 4,
      paddingBottom: 150,
    },
  })


class ContentLayout extends Component {
  render() {
    const { title, description, keywords, children, classes, image, url } = this.props

    return (
      <Layout>
        <Banner image={image} />

        <div className={classes.layout}>
          <Grid container spacing={16}>
            <SEO title={title} description={description} keywords={keywords} {...{ image: image.src, url }}/>

            <Grid item xs={12} md={8}>
              <Paper className={classes.paperLayout}>
                {children}
              </Paper>
            </Grid>

            <Sidebar />
          </Grid>
        </div>
      </Layout>
    )
  }
}

export default withStyles(styles)(ContentLayout)

