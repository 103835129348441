import React from "react"
import Typography from "@material-ui/core/Typography"
import ContentLayout from "../components/ContentLayout"
import { StaticQuery } from "gatsby"

export default class NotFoundPage extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            placeholderImage: file(relativePath: { eq: "DSC05483.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `}
        render={data => (
          <ContentLayout
            title={"404: Not found"}
            description={"404: Not found"}
            keywords={["404"]}

            image={data.placeholderImage.childImageSharp.fluid}
            url={"/404"}
          >
            <Typography variant="h2" component="h1" gutterBottom>NOT FOUND</Typography>
            <Typography>You just hit a route that doesn&#39;t exist... the sadness.</Typography>
          </ContentLayout>
        )}
      />
    );
  }
}
