import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { getSchemaOrgJSONLD } from './SchemaOrg'

function SEO({ description, keywords = [], title, isPost, datePublished, image, url = "/"}) {
  const defaultKeywords = ['FPV', 'Racer', 'Zug', 'FPV Racer Zug', 'drone', 'fpv', 'fpvracing']
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {

        const { siteMetadata } = data.site
        const metaDescription = description || siteMetadata.description

        return (
          <Helmet
            htmlAttributes={{
              lang: "en",
            }}
            title={title}
            titleTemplate={`%s - ${siteMetadata.title}`}
            defaultTitle={siteMetadata.title}
          >
            {/* General tags */}
            <meta name="description" content={metaDescription} />


            {/* Schema.org tags */}
            <script type="application/ld+json">
              {JSON.stringify(getSchemaOrgJSONLD({
                isBlogPost: isPost,
                datePublished: datePublished,
                image: image,
                title,
                description,
                url,
              }))}
            </script>

            {/* OpenGraph tags */}
            <meta property="og:title" content={title ? title : siteMetadata.title} />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:site_name" content="FPV Racer Zug" />

            <meta property="og:image" content={siteMetadata.siteUrl + image}/>

            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:image" content={siteMetadata.siteUrl + image} />
            <meta name="twitter:site" content="FPV Racer Zug" />

            {/* <meta name="twitter:creator" content={siteMetadata.social.twitter} /> */}
            <meta name="twitter:title" content={title ? title : siteMetadata.title} />
            <meta name="twitter:description" content={metaDescription} />

            <meta name="keywords" content={[...defaultKeywords, ...keywords].join(`, `)} />

            <link rel="canonical" href={siteMetadata.siteUrl + url} />
            <meta property="og:url" content={siteMetadata.siteUrl + url} />

            {isPost ? <meta property="og:type" content="article" /> : <meta property="og:type" content="website" />}

            {/* <meta name="image" content={image} /> */}
            {/* <meta name="twitter:image" content={image} /> */}
            {/* <meta property="og:image" content={image} /> */}

          </Helmet>
        )
      }}
    />
  )
}

// SEO.propTypes = {
//   description: PropTypes.string,
//   lang: PropTypes.string,
//   meta: PropTypes.array,
//   keywords: PropTypes.arrayOf(PropTypes.string),
//   title: PropTypes.string.isRequired,
// }

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        siteUrl
        title
        description
        author
        social {
          twitter
        }
      }
    }
  }
`
