import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import facebook from 'simple-icons/icons/facebook'
import instagram from 'simple-icons/icons/instagram'
import IconButton from './IconButton'
import Img from "gatsby-image"
import Link from "./Link"
import { graphql, StaticQuery } from "gatsby"

const styles = (theme) => ({
  personContainer: {
    height: 28,
    position: "relative",
  },

  personIcon: {
    backgroundColor: theme.palette.primary.main,
    position: "absolute",
    transform: "translateX(-50%)",
    left: "50%",
    top: -38,
    height: 60,
    width: 60,
  },

  sidebar: {
    padding: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2
    // backgroundColor: theme.palette.grey[200],
  }
})

class Sidebar extends Component {
  render() {
    const { classes } = this.props


    return (
      <Grid item xs={12} md={4}>
        <Paper elevation={2} className={classes.sidebar}>
          <Typography variant="h6">
            Links
          </Typography>

          <Typography>
            <a href="https://swissdroneleague.ch/" target="_blank" rel="noopener noreferrer">Swiss Drone League</a>
          </Typography>
          <Typography>
            <a href="https://fpvracer.ch/" target="_blank" rel="noopener noreferrer">FPV Racer Schweiz</a>
          </Typography>
        </Paper>

        <Paper elevation={2} className={classes.sidebar}>
          <Typography variant="h6">
            Sponsoren
          </Typography>

          <Link external href="https://www.swaytronic.ch/" target="_blank" rel="noopener noreferrer">
            <StaticQuery
              query={graphql`
                query {
                  placeholderImage: file(relativePath: { eq: "swaytronic_sponsor.png" }) {
                    childImageSharp {
                      fixed(width: 292) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
              `}
              render={data => <Img alt="Swaytronic Logo" fixed={data.placeholderImage.childImageSharp.fixed} />
              }
            />
          </Link>
        </Paper>

        <Paper elevation={2} className={classes.sidebar}>
          <Typography variant="h6">
            Social Media
            <IconButton icon={instagram} to="https://www.instagram.com/fpvracerzug/" />
            <IconButton icon={facebook} to="https://www.facebook.com/FPVracerZUG/" />
          </Typography>

          Instagram Feed
        </Paper>
      </Grid>
    )
  }
}

export default withStyles(styles)(Sidebar);
