import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import GatsbyLink from 'gatsby-link';


const styles = (theme) => ({
  noLink: {
    color: "inherit",
    textDecoration: "none",
  }
})

class Link extends Component {
  render() {
    const { classes, external, ...props } = this.props

    if(external) {
      // eslint-disable-next-line
      return <a className={classes.noLink} {...props}/>
    } else {
      return <GatsbyLink className={classes.noLink} {...props} />
    }
  }
}

export default withStyles(styles)(Link)
