import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Img from "gatsby-image"

const styles = (theme) =>
  createStyles({
    root: {
      width: '100%',
      height: 100,
    },
  })

class Banner extends Component {
  render() {
    const { classes, image } = this.props
    console.log(this.props)

    return (
      <div className={classes.layout}>
        <Img
          fluid={image}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="banner"
          style={{height: 300}}
        />
      </div>
    )
  }
}

export default withStyles(styles)(Banner)

