import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import CssBaseline from "@material-ui/core/CssBaseline"
import withStyles from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Helmet from 'react-helmet'
import Button from '@material-ui/core/Button';
import Link from './Link'

const styles = (theme) =>
  createStyles({
    footer: {
      backgroundColor: theme.palette.background.paper,
      // marginTop: theme.spacing.unit * 8,
      padding: `${theme.spacing.unit * 6}px 0`,
      position: "absolute",
      bottom: 0,
      width: "100%"
    },

    navigation: {
      display: "flex"
    },

    titleFlex: {
      display: "flex",
      justifyContent: "space-between"
    }
  })


class Layout extends Component {
  render() {
    const { children, classes } = this.props

    return (
      <StaticQuery
        query={sidebarQuery}
        render={({site}) => {
          const { title } = site.siteMetadata

          return (
            <div style={{position: "relative", minHeight: "100vh", overflowX: "hidden"}}>
              <Helmet>
                <meta
                  name="viewport"
                  content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
                />
              </Helmet>

              <CssBaseline />

              <AppBar position="static" color="primary" elevation={0}>
                <Toolbar className={classes.titleFlex}>
                  <Link to="/">
                    <Typography
                      component="h2"
                      variant="h6"
                      color="inherit"
                      noWrap
                    >
                      { title }
                    </Typography>
                  </Link>


                  <nav className={classes.navigation}>
                    <Link to="/ueber-uns/">
                      <Button color="inherit">
                        Über uns
                      </Button>
                    </Link>

                    <Link to="/was-ist-fpv/">
                      <Button color="inherit">
                        Was ist FPV Racing
                      </Button>
                    </Link>

                    <Link to="/kontakt/">
                      <Button color="inherit">
                        Kontakt
                      </Button>
                    </Link>
                  </nav>
                </Toolbar>
              </AppBar>

              <main>
                <noscript>
                  <Typography>This page works faster with JavaScript <a href="https://www.enable-javascript.com/">enabled</a></Typography>
                </noscript>

                {children}
              </main>


              <footer className={classes.footer}>
                <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
                  Copyright © 2019 FPV Racer Zug
                </Typography>
              </footer>
            </div>
          )
        }}
      />
    );
  }
}


const sidebarQuery = graphql`
{
  site {
    siteMetadata {
      title
    }
  }
}`

export default withStyles(styles)(Layout)
