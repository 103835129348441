import React, { Component } from 'react'
import IconButton2 from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles';
import Link from './Link';


const styles = (theme) => ({
  svgButton: {
    '& div': {
      widht: 25,
      height: 25,
    },
    '& svg': {
      widht: 25,
      height: 25,
    }
  }
})

class IconButton extends Component {
  render() {
    const { icon, classes, to } = this.props


    return (
      <Link external href={to} aria-label={icon.title}>
        <IconButton2 style={{ fill: "#" + icon.hex }} aria-label={icon.title} className={classes.svgButton}  >
          <div dangerouslySetInnerHTML={{ __html: icon.svg }}/>
        </IconButton2>
      </Link>
    )
  }
}

export default withStyles(styles)(IconButton)
