const siteUrl = 'https://pascalluginbuehl.ch'
export const getSchemaOrgJSONLD = ({
  isBlogPost,
  url,
  title,
  image,
  description,
  datePublished,
}) => {
  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: siteUrl + url,
      name: title,
      alternateName: 'Pascal Luginbuehl',
    },
  ];

  return isBlogPost
    ? [
      ...schemaOrgJSONLD,
      {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            item: {
              '@id': siteUrl + url,
              name: title,
              image,
            },
          },
        ],
      },
      {
        '@context': 'http://schema.org',
        '@type': 'BlogPosting',
        url: siteUrl + url,
        name: title,
        alternateName: 'Pascal Luginbuehl',
        headline: title,
        image: {
          '@type': 'ImageObject',
          url: siteUrl + image,
        },
        description,
        author: {
          '@type': 'Person',
          name: 'Pascal Luginbuehl',
        },
        publisher: {
          '@type': 'Organization',
          url: siteUrl,
          logo: siteUrl + "/icons/icon-512x512.png",
          name: 'Pascal Luginbuehl',
        },
        mainEntityOfPage: {
          '@type': 'WebSite',
          '@id': siteUrl,
        },
        datePublished,
      },
    ]
    : schemaOrgJSONLD;
};
